import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import {applicationSelector} from '../../slices/application';
import HealthCheck from '../Forms/HealthCheck/HealthCheck';
import ChooseQuantity from '../Forms/Quantity/ChooseQuantity';
import ChooseTubing from '../Forms/Tubing/ChooseTubing';
import './Forms.scss';
import AddEditArt from './Print/AddEditArt';
import AddEditText from './Print/AddEditText';
import SelectArt from './Print/SelectArt';
import SelectOptions from './Print/SelectOptions';

const Forms = () => {
  const {appTab, printTab, heatshrinkCompatibility} =
    useSelector(applicationSelector);
  const navigate = useNavigate();
  const location = useLocation();

useEffect(() => {
  const currentPath = location.pathname;
  const newTab = appTab.split('?')[0];

  const appTabPaths = {
    'choose-tubing': '/choose-tubing',
    'choose-quantity': '/choose-quantity',
    'choose-print': {
      'select-art': '/select-art',
      'add-art': '/add-art',
      'edit-art': '/edit-art',
      'add-text': '/add-text',
      'edit-text': '/edit-text',
      'select-options': '/select-options',
    },
  };

  const newPath = appTabPaths[newTab] instanceof Object ? appTabPaths[newTab][printTab] : appTabPaths[newTab];

  if (newPath && currentPath !== newPath) {
    navigate(newPath);
  }
}, [appTab, printTab, location]);

  return (
    <div className="hs-forms">
      <Routes>
        <Route path="/health_check" element={<HealthCheck />} />
        <Route
          path="/"
          element={<ChooseTubing compatibility={heatshrinkCompatibility} />}
        />
        <Route path="/choose-quantity" element={<ChooseQuantity />} />
        <Route
          path="/choose-tubing"
          element={<ChooseTubing compatibility={heatshrinkCompatibility} />}
        />
        <Route path="/add-art" element={<AddEditArt />} />
        <Route path="/edit-art" element={<AddEditArt />} />
        <Route path="/add-text" element={<AddEditText />} />
        <Route path="/edit-text" element={<AddEditText />} />
        <Route path="/select-art" element={<SelectArt />} />
        <Route path="/select-options" element={<SelectOptions />} />
      </Routes>
    </div>
  );
};

export default Forms;
