import React, {useLayoutEffect, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  applicationSelector,
  updateDesign,
  updateUuid,
  updateAppTab
} from '../../../slices/application';
import {saveDesignToDynamo, getLatestDesign, getDesignById} from '../../../api';
import Canvas from '../../Canvas/Canvas';
import Footer from '../../Footer/Footer';
import Forms from '../../Forms/Forms';
import Header from '../../Header/Header';
import SaveModal from '../../Modal/SaveModal';
import DisclaimerModal from '../../Modal/DisclaimerModal';
import InfoModal from '../../Modal/InfoModal';
import Nav from '../../Nav/Nav';
import Tooltip from '../../Tooltip/Tooltip';
import './Printing.scss';

const Printing = () => {
  const {uuid} = useSelector(applicationSelector);
  const drawing = document.getElementsByClassName('drawing')[0];
  const {design, appTab} = useSelector(
    applicationSelector,
    (oldState, newState) => {
      return (
        oldState.design.objects === newState.design.objects &&
        oldState.design.id === newState.design.id &&
        oldState.design.heatshrinkColor === newState.design.heatshrinkColor &&
        oldState.design.printColor === newState.design.printColor &&
        oldState.design.diameter === newState.design.diameter &&
        oldState.design.finish === newState.design.finish &&
        oldState.design.shrinkRatio === newState.design.shrinkRatio &&
        oldState.design.length === newState.design.length &&
        oldState.design.orderInstructions ===
          newState.design.orderInstructions &&
        oldState.appTab === newState.appTab
      );
    }
  );

  const {currentKonvaObject} = useSelector(
    applicationSelector,
    (oldState, newState) => {
      return (
        oldState.currentKonvaObject?.id === newState.currentKonvaObject?.id
      );
    }
  );
  const site = design.siteCode;

  const [previousDiameter, setPreviousDiameter] = useState(design.diameter);
  const [previousLength, setPreviousLength] = useState(design.length);
  const [previousId, setPreviousId] = useState(
    localStorage.getItem('lastDesign')
  );

  useEffect(() => {
    const lastDesign = localStorage.getItem('lastDesign');
    if (lastDesign) {
      fetchDesignById(lastDesign);
    }
  }, []);

  /* eslint-disable camelcase */
  useEffect(() => {
    if (uuid) {
      dispatch(
        updateDesign({...design, userUUID: uuid, user_uuid: uuid, userId: uuid})
      );
    }
  }, [uuid]);
  /* eslint-enable camelcase */

  useEffect(() => {
    const data = {
      type: 'isEditing',
      value: true
    };
    if (currentKonvaObject == null) {
      data.value = false;
    }
    window.parent.postMessage(data, '*');
  }, [currentKonvaObject]);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if ((queryParams.get('uuid')?.length ?? -1) > 0)
      dispatch(updateUuid(queryParams.get('uuid')));
    if ((queryParams.get('designId')?.length ?? -1) > 0) {
      fetchDesignById(queryParams.get('designId'));
      dispatch(updateAppTab('choose-quantity'));
    } else {
      fetchLatestDesign(queryParams.get('uuid'));
    }
  }, []);

  useEffect(() => {
    // Check if drawing has non-zero clientHeight and clientWidth
    if (drawing?.clientHeight > 0 && drawing?.clientWidth > 0) {
      updateDesignWithCanvasSize(design);
    }
  }, [appTab, drawing?.clientHeight, drawing?.clientWidth]);
  useEffect(() => {
    if (design?.designId != previousId || appTab != 'choose-tubing') {
      return;
    }
    if (
      design.length * 1.05 != previousLength ||
      (design.diameter * 3.14) / 2 != previousDiameter
    ) {
      repositionObjects();
    }
  }, [design.length, design.diameter]);

  useEffect(() => {
    // Check if the current designId is empty
    if (design.designId === '') {
      // Get the lastDesign from local storage
      const lastDesign = localStorage.getItem('lastDesign');
      // If there was a value in the lastDesign local storage, assign the designId to that value
      if (lastDesign) {
        dispatch(updateDesign({...design, designId: lastDesign}));
      }
    }
    if (design.designId != '') {
      setPreviousId(design.designId);
      saveDesignAsync(design);
    }
  }, [
    design.heatshrinkColor,
    design.printColor,
    design.diameter,
    design.finish,
    design.shrinkRatio,
    design.length,
    design.objects,
    design.orderInstructions,
    design.designId
  ]);

  function repositionObjects() {
    const yFactor = (design.length * 1.05) / previousLength;
    const xFactor = (design.diameter * 3.14) / 2 / previousDiameter;

    const objects = design.objects.map((obj) => {
      const copy = {...obj};
      copy.scaleX = obj.scaleX * xFactor;
      copy.scaleY = obj.scaleY * yFactor;
      return copy;
    });

    setPreviousDiameter((design.diameter * 3.14) / 2);
    setPreviousLength(design.length * 1.05);

    dispatch(updateDesign({...design, objects: objects}));
  }

  const saveDesignAsync = async function (design) {
    if (design.designId == '') {
      dispatch(
        updateDesign({...design, designId: localStorage.getItem('lastDesign')})
      );
    }
    try {
      const response = await saveDesignToDynamo(design);
      if (response?.data?.designId != design.designId) {
        updateDesignLocalstorage(response.data.designId);
        /* eslint-disable camelcase */
        const updatedData = {
          ...response.data,
          ...(uuid && {userUUID: uuid, user_uuid: uuid, userId: uuid})
        };
        /* eslint-enable camelcase */

        dispatch(updateDesign(updatedData));
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  const updateDesignLocalstorage = function (designId) {
    localStorage.setItem('lastDesign', designId);
  };

  const updateDesignWithCanvasSize = function (design) {
    let drawing = document.getElementsByClassName('drawing')[0];

    // If the class name 'drawing' is not found, do not proceed with the function
    if (!drawing) {
      return;
    }
    // Add a counter to prevent infinite loop
    let counter = 0;
    while (
      (drawing?.clientHeight === 0 || drawing?.clientHeight == null) &&
      appTab === 'select-options' &&
      counter < 100
    ) {
      drawing = document.getElementsByClassName('drawing')[0];
      counter++;
    }

    // Check if the canvas has a non-zero width and height
    if (drawing?.clientHeight > 0 && drawing?.clientWidth > 0) {
      dispatch(
        updateDesign({
          ...design,
          canvasHeight: drawing.clientHeight,
          canvasWidth: drawing.clientWidth
        })
      );
    } else {
      console.error(
        'Cannot update design with a canvas of zero width or height'
      );
    }
  };

  async function fetchLatestDesign(userUUID) {
    try {
      const response = await getLatestDesign(userUUID);
      updateDesignLocalstorage(response.data.designId);
      updateDesignWithCanvasSize({...response.data});
      dispatch(updateDesign({...response.data}));
    } catch (error) {
      console.log(error.message);
    }
  }

  async function fetchDesignById(designId) {
    try {
      const response = await getDesignById(designId);
      updateDesignLocalstorage(response.data.designId);
      updateDesignWithCanvasSize({...response.data});
      dispatch(updateDesign({...response.data}));
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <main className="hs-page hs-lab printing">
      <div className={'container hs-page-container ' + site}>
        <Header />
        <section className="hs-designer">
          <Nav />
          <Forms />
          <Canvas />
        </section>
        <Footer />
      </div>
      <Tooltip />
      <SaveModal />
      <DisclaimerModal />
      <InfoModal />
    </main>
  );
};

export default Printing;
