import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  toggleIsArtUpdated,
  updatePrintTab,
  updateRecentUploads,
  updateInfoModal,
  applicationSelector,
  initialState,
  updateCurrentKonvaObject
} from '../../slices/application';
import {artUpload} from '../../api';
import config from '../../config';
import {extractCanvasMeasurementsFromDesign} from '../../utils/utils';

const FileUpload = ({content, classes, id}) => {
  const cdnUri = config.cdnUri;
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const {uuid, currentKonvaObject, design} = useSelector(applicationSelector);
  const {tubeHeight, tubeWidth, drawableWidth, drawableHeight} =
    extractCanvasMeasurementsFromDesign(design);

  // When the button is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  // When a file is uploaded
  const handleChange = (event) => {
    const filesUploaded = event.target.files;
    addArtwork(filesUploaded);
  };

  function handleDrop(e) {
    const dataTransfer = e.dataTransfer;
    const files = dataTransfer.files;
    addArtwork(files);
  }

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function checkIfUploadSmallEnough(filesArray) {
    let bytes = 0;
    filesArray.forEach((file) => (bytes += file.size));
    const Mb = bytes / (1024 * 1024);
    if (Mb <= 30) {
      return true;
    } else {
      dispatch(
        updateInfoModal({
          isInfoModalVisible: true,
          infoText: 'Total Upload Size Cannot Exceed 30Mb'
        })
      );
    }
  }

  function handleUploadResponse(res) {
    if (res.data.totalUploads === 1) {
      const file = res.data.uploads[0];
      setCurrentKonvaObject(file);
      dispatch(updatePrintTab('add-art'));
    } else {
      dispatch(updatePrintTab('select-art'));
    }
  }

  const validateFiles = (files) => {
    return (files != null && files != '');
  };

  const processUploadResponse = (res) => {
    if (!uploadHasErrors(res)) {
      handleUploadResponse(res);
    } else {
      handleArtUploadErrors(res);
    }
    const uploadedIds = res.data.uploads.map(obj => obj.artworkId);
    dispatch(updateRecentUploads(uploadedIds));
    dispatch(toggleIsArtUpdated());
  };

  const uploadFiles = async (filesArray) => {
    const res = await uploadImages(filesArray);
    if (res) {
      processUploadResponse(res);
    } else {
      dispatch(
        updateInfoModal({
          isInfoModalVisible: true,
          infoText: 'Upload failed. Please try again.'
        })
      );
    }
  };

  const addArtwork = async function (files) {
    if (!validateFiles(files)) {
      return;
    }
    const filesArray = Array.from(files);
    if (checkIfUploadSmallEnough(filesArray)) {
      await uploadFiles(filesArray);
    }
  };

  function uploadHasErrors(res) {
    return res.data?.totalErrors > 0;
  }

  function handleArtUploadErrors(res) {
    if (!res) {
      dispatch(
        updateInfoModal({
          isInfoModalVisible: true,
          infoText: 'Upload failed. Please try again.'
        })
      );
      return;
    }
    res.data.totalFiles === 1
      ? handleSingleFileError(res)
      : handleMuliFileError(res);
  }

  function handleSingleFileError(res) {
    const fileName = res.data.errors[0].fileName;
    const errorText =
      res.data.totalWrongFileType > 0
        ? 'is not a JPG or PNG'
        : 'exceeds 3MB max file size';
    dispatch(
      updateInfoModal({
        isInfoModalVisible: true,
        infoText: `${fileName} ${errorText}`
      })
    );
  }

  function handleMuliFileError(res) {
    const totalUploads = res.data.totalUploads;
    const totalFiles = res.data.totalFiles;
    const fileTypeErrors = res.data.totalWrongFileType;
    const fileSizeErrors = res.data.totalFileTooLarge;

    const fileTypeMessage =
      fileTypeErrors > 0
        ? `${fileTypeErrors} ${
            fileTypeErrors > 1 ? 'images are' : 'image is'
          } not a JPG or PNG`
        : '';
    const fileSizeMessage =
      fileSizeErrors > 0
        ? `${fileSizeErrors} ${
            fileSizeErrors > 1 ? 'images exceed' : 'image exceeds'
          } 3MB max file size`
        : '';

    const infoMessage = (
      <>
        <p>
          {totalUploads} of {totalFiles} images uploaded.
        </p>
        <p>
          {fileTypeMessage}
          <br />
          {fileSizeMessage}
        </p>
      </>
    );
    dispatch(
      updateInfoModal({
        isInfoModalVisible: true,
        infoText: infoMessage
      })
    );
    if (totalUploads > 0) {
      dispatch(updatePrintTab('select-art'));
    }
  }

  const uploadImages = async function (filesArray) {
    return artUpload(filesArray, uuid);
  };

  function resize(image) {
    const resized = {src: `${cdnUri}/${image.processed_image}`};

    // fit image within it + padding
    const imageAspect = image.imageWidth / image.imageHeight;
    const drawableAspect = drawableWidth / drawableHeight;
    if (drawableAspect > imageAspect) {
      // height constrained
      resized.imageHeight = Math.round(drawableHeight * 0.8);
      resized.imageWidth = Math.round(imageAspect * resized.imageHeight);
    } else {
      // width constrained
      resized.imageWidth = Math.round(drawableWidth * 0.8);
      resized.imageHeight = Math.round(resized.imageWidth / imageAspect);
    }
    return resized;
  }

  function setCurrentKonvaObject(artwork) {
    const resizedImage = resize(artwork);

    const color = initialState.printColors.find(
      ({value}) => value === design.printColor
    ).rgb;

    // check if currentKonvaObject
    let id = design.lastId + 1;
    let x = 0.5;
    let y = 0.5;
    let invert = true;
    let rotation = 0;
    let threshold = 0.8;
    if (currentKonvaObject) {
      id = currentKonvaObject.id;
      x = currentKonvaObject.x;
      y = currentKonvaObject.y;
      invert = currentKonvaObject.invert;
      rotation = currentKonvaObject.rotation;
      threshold = currentKonvaObject.threshold;
    }

    let scaleX = 0.8;
    let scaleY = 0.8;
    const imageAspect = resizedImage.imageWidth / resizedImage.imageHeight;
    if (imageAspect > drawableWidth / drawableHeight) {
      scaleY = (scaleX * tubeWidth) / imageAspect / tubeHeight;
    } else {
      scaleX = (scaleY * tubeHeight * imageAspect) / tubeWidth;
    }

    const imageObject = {
      id: String(id),
      key: String(id),
      type: 'image',
      x: x,
      y: y,
      scaleX: scaleX,
      scaleY: scaleY,
      skewX: 0,
      skewY: 0,
      height: artwork.imageHeight,
      width: artwork.imageWidth,
      offsetX: artwork.imageWidth / 2,
      offsetY: artwork.imageHeight / 2,
      threshold: threshold, // they can't see this before adding to page
      url: resizedImage.src,
      rotation: rotation,
      fill: 'transparent',
      selectColor: color,
      invert: invert,
      isSelected: false
    };

    // set the selected image src, to be used on the edit img
    dispatch(updateCurrentKonvaObject(imageObject));
  }

useEffect(() => {
  const dropArea = document.getElementById(id);

  const eventNames = ['dragenter', 'dragover', 'dragleave', 'drop'];
  eventNames.forEach((eventName) => {
    dropArea.addEventListener(eventName, preventDefaults, false);
  });
  dropArea.addEventListener('drop', handleDrop, false);

  return () => {
    eventNames.forEach((eventName) => {
      dropArea.removeEventListener(eventName, preventDefaults, false);
    });
    dropArea.removeEventListener('drop', handleDrop, false);
  };
}, [id, preventDefaults, handleDrop]);

  return (
    <>
      <button
        title="Upload a JPG or PNG"
        type="button"
        className={classes}
        id={id}
        onMouseDown={handleClick}>
        {content}
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{display: 'none'}}
        onChange={handleChange}
        accept=".jpg,.jpeg,.jif,.jpe,.jfif,.png,.bmp,.gif"
        multiple
      />
    </>
  );
};

export default FileUpload;
